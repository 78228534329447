import axios from "axios"

const axiosHandler = axios.create({
    // baseURL: process.env.REACT_APP_PUBLIC_ROUTE,
    baseURL: process.env.REACT_APP_API,
    withCredentials: true, // Send cookies with the request
    headers: {
      'Content-Type': 'application/json'
    }
})

export default axiosHandler