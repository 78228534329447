import {configureStore } from "@reduxjs/toolkit"
import CalenderReducer from "./CalenderSclice"
import userReducer from "./userSclice"

const store = configureStore({
    reducer:{
        Calender:CalenderReducer,
        user:userReducer
    }
})

export default store