import React, { useState,useEffect }  from 'react'
import {useForm} from "react-hook-form"
import axiosHandler from "../../../api/axios"
// import {decodeJWT} from './jwtDecode';
import {Navigate} from "react-router-dom"
import {useAuth} from "../../../hooks/useAuth"
function Login() {
 
  const [status,setStatus ] = useState(false)
  const {register,handleSubmit,setError,formState:{errors,isSubmitting}} = useForm()
  const{ setUser} = useAuth()

  // useEffect(() => {
  //   const token = localStorage.getItem("token")
  //   console.log("Token",token)
  //   if (token) {
  //     try {
  //       const decoded = decodeJWT(token);
  //       setUser(decoded)
  //       setStatus(true)
  //     } catch (error) {
  //       console.error('Invalid token', error);
  //     }
  //   }
  // },[setUser]);

    
    async function methodSubmit(data) {
      try {
        
        const response= await axiosHandler.post('/login',data)
        if (response.data.status === true) {
          // Set token in localStorage
          // document.cookie = `token=${response.data.token}; path=/`;
          localStorage.setItem('token', response.data.token);
          setUser(response.data.user);
          setStatus(response.data.status);
        } else {
          setError("root", { message: response.data.message });
        }
      } catch (error) {
        console.error('Error during login:', error);
        setError("root", { message: error.response.data.message });
      }
    }
    
  return (
    <main className="h-full flex flex-col justify-center bg-white  items-center ">
   
      <div className="flex flex-col items-center z-10">
        <img src="/APT.png" className="mix-blend-color-burn h-36" alt="Apt" />
        {/* <img src="https://aashvipt.com/wp-content/uploads/2023/01/hm1.jpg" className="w-4/12" alt="something" /> */}
      </div>
      <form className="w-10/12 rounded-md  bg-[#ffffff34] flex flex-col justify-center items-center" onSubmit={handleSubmit(methodSubmit)}>
        <h1 className="text-4xl font-extralight font-['math'] italic text-[#3e1a58]">Login</h1>
        
        
        <input {...register("email",{
          required:"Email is require"
        })} className="w-5/12 rounded p-1 border border-blue-400 mt-[2%] " type="email" placeholder='Email' />
        {errors.email && <div className='text-lg font-bold text-red-600'>{errors.email.message}</div>}
        
        <input {...register("password",{
          required:"Password is require"
        })} className="w-5/12 rounded p-1 border border-blue-400 mt-[2%] " type="password" placeholder='Password' />
        {errors.phone && <div className='text-lg font-bold text-red-600'>{errors.phone.message}</div>}
      
      
  <button type="submit" className='button-PT-registration' disabled={isSubmitting}>
    {isSubmitting?"Loading...": "Submit"}
  </button>

        {errors.root && <div className='text-lg font-bold text-red-600'>{errors.root.message}</div>}
      </form>

        {status&& <Navigate to="/APTlabUserPannel" />}
      
      
    </main>
  )
}

export default Login
