import React from "react";
import "./popup.css";
export const Popup = ({ text,year,Delete,noDelete }) => {
    console.log(year)
  return (
    <div className="popup-container">
     <div className="popup-body">
      <h1 className="m-2 text-2xl font-bold">{text} {year}</h1>

      <button className="text-white font-serif p-2 pl-2 text-sm  pr-2 w-1/4 mt-20 mr-20 bg-red-500 Calender-table rounded-md font-bold" onClick={Delete}>Delete</button>
      <button className="text-white font-serif p-2 pl-2 text-sm  pr-2 w-1/4 mt-20 bg-green-500 Calender-table rounded-md font-bold" onClick={noDelete}>Cancle</button>
     </div>
    </div>
  );
};