import React from 'react'
import "./Nav.css"
import { Outlet,Link } from 'react-router-dom'

function Nav() {
  return (
    <div className='h-screen '>
      <nav className='bg-[#171717] p-2 flex justify-between'>
        <ul className='flex text-white space-x-3 m-1 font-bold text-lg'>
          {/* <Link to={'/calender/#1'}><li>Calender</li></Link> */}
          {/* <Link to={'APTlabUserPannel/display'}><li>Calender</li></Link> */}
          <Link to={'/userregistration'}><li>Registration</li></Link>
          {/* <Link to={'APTlabUserPannel/userDownload'}><li>Registerd Labs</li></Link> */}
        </ul>
        <Link to={"/APTlogin"}><button className='p-2 bg-white text-black border text-sm'>Admin Login</button></Link>
      </nav>
      <img src="/APT.png" className='backgroung-image' alt="some" />
      <Outlet/>
    </div>
  )
}

export default Nav
