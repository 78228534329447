import React from 'react';
import "./UsersRegistration/UserFormDownload.css" // Optional if you want to style via a CSS file

const Spinner = () => {
  return (
    <div className="spinner-container">
      <div className="spinner"></div>
    </div>
  );
};

export default Spinner;
