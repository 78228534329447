import React, { Suspense, useEffect, useState } from 'react';
import axios from '../../api/axios';
import Loading from "../Loader"
const ShowAllYears = React.lazy(() => import('./ShowAllYears'));

function CssformDownload() {
  const [allYears, setAllYears] = useState([]);
  const [loading, setLoading] = useState(true);

  useEffect(() => {
    async function getYears() {
      try {
        const response = await axios.get('/feedbackyears');
        setAllYears(response.data.years); // Assuming response.data.years contains the array of years
      } catch (error) {
        console.error('Error fetching years:', error);
      } finally {
        setLoading(false);
      }
    }
    getYears();
  }, []);

 
  return (
    <div>
      <h1 className="font-bold text-center font-sans text-3xl">
        Download the CSV Form of Year
      </h1>
      <div className="flex justify-center items-center mt-4">
        {loading ? (
          <Loading />
        ) : (
          <Suspense fallback={<Loading/>}>
            <ShowAllYears years={allYears} />
          </Suspense>
        )}
      </div>
    </div>
  );
}

export default CssformDownload;
