import React, { useState } from "react";
import { useSelector, useDispatch } from "react-redux";
import axios from "../../api/axios.js";
import { Link, Navigate } from "react-router-dom";
import toast from "react-hot-toast";
import {resetCalender} from "../../redux/CalenderSclice.js"

function EditPage() {
  const dispatch = useDispatch()
  
  const Calender = useSelector((state) => state.Calender);
  const [navigate, setnavigate] = useState(false);
  const [btnDisable, setBtnDisable] = useState(false);
  console.log(Calender.january);
  async function handleClick() {
    try {
      setBtnDisable(true);
      let response
      if(Calender.isUpdate){
         response = await axios.put("/updateCalender", Calender);
      }else{
         response = await axios.post("/inputCalender", Calender);

      }
      toast.success(response.data.message);
      console.log(response);
      setnavigate(true);
      dispatch(resetCalender())
    } catch (error) {
      toast.error(error.response.data.message);
      console.log(error);
    } finally {
      setBtnDisable(false);
    }
  }
  
  return (
    <div className="h-screen flex flex-col justify-between items-center">
      <h1 className="text-center font-bold text-xl">
        Calender Of Year {Calender.year}
      </h1>
      <div className="w-4/12 flex justify-between m-3 mt-10">
      <p className="text-center  font-extrabold text-xl ">
        January{" "}
      </p>
        <Link to={"/APTlabUserPannel/edit/calender/#january"}>
          <button className="text-lg border-2 border-green-400 bg-green-500 w-20 font-bold rounded-md text-white Calender-table">
            Edit
          </button>
        </Link>
      </div>
      <table className="w-10/12">
        <thead>
          <tr className="text-center border-2 border-red-600">
            <th>PT Round Nos.</th>
            <th>Matrix / Product</th>
            <th>Registration Closing Date</th>
            <th>Advance Fee Amount</th>
          </tr>
        </thead>

        {Calender.january.map((iteam) => (
          <tbody className>
            <tr className="text-center border border-black">
              <td>{iteam.Round}</td>
              <td>{iteam.roundName}</td>
              <td>{new Date(iteam.Final).toLocaleDateString("en-GB").replace(/\//g, "-")}</td>
              <td>₹ {iteam.Price}</td>
            </tr>
          </tbody>
        ))}
      </table>
      <div className="w-4/12 flex justify-between m-3 mt-10">
      <p className="text-center  font-extrabold text-xl ">
        February{" "}
      </p>
        <Link to={"/APTlabUserPannel/edit/calender/#february"}>
          <button className="text-lg border-2 border-green-400 bg-green-500 w-20 font-bold rounded-md text-white Calender-table">
            Edit
          </button>
        </Link>
      </div>
      <table className="w-10/12">
        <thead>
          <tr className="text-center border-2 border-red-600">
            <th>PT Round Nos.</th>
            <th>Matrix / Product</th>
            <th>Registration Closing Date</th>
            <th>Advance Fee Amount</th>
          </tr>
        </thead>

        {Calender.february.map((iteam) => (
          <tbody className="">
            <tr className="text-center border border-black">
              <td>{iteam.Round}</td>
              <td>{iteam.roundName}</td>
              <td>{new Date(iteam.Final).toLocaleDateString("en-GB").replace(/\//g, "-")}</td>
              <td>₹ {iteam.Price}</td>
            </tr>
          </tbody>
        ))}
      </table>
      <div className="w-4/12 flex justify-between m-3 mt-10">

      <p className="text-center font-extrabold text-xl">
        March{" "}
        
      </p>
        <Link to={"/APTlabUserPannel/edit/calender/#march"}>
          <button className="text-lg border-2 border-green-400 bg-green-500 w-20 font-bold rounded-md text-white Calender-table">
            Edit
          </button>
        </Link>
      </div>
      <table className="w-10/12">
        <thead>
          <tr className="text-center border-2 border-red-600">
            <th>PT Round Nos.</th>
            <th>Matrix / Product</th>
            <th>Registration Closing Date</th>
            <th>Advance Fee Amount</th>
          </tr>
        </thead>

        {Calender.march.map((iteam) => (
          <tbody className="">
            <tr className="text-center border border-black">
              <td>{iteam.Round}</td>
              <td>{iteam.roundName}</td>
              <td>{new Date(iteam.Final).toLocaleDateString("en-GB").replace(/\//g, "-")}</td>
              <td>₹ {iteam.Price}</td>
            </tr>
          </tbody>
        ))}

      </table>
        <div className="flex w-4/12 justify-between m-3 mt-7">
      <p className="text-center font-extrabold text-xl">
        April{" "}
      </p>
        <Link to={"/APTlabUserPannel/edit/calender/#april"}>
          <button className="text-lg border-2 border-green-400 bg-green-500 w-20 font-bold rounded-md text-white Calender-table">
            Edit
          </button>
        </Link>
        </div>
      <table className="w-10/12">
        <thead>
          <tr className="text-center border-2 border-red-600">
            <th>PT Round Nos.</th>
            <th>Matrix / Product</th>
            <th>Registration Closing Date</th>
            <th>Advance Fee Amount</th>
          </tr>
        </thead>

        {Calender.april.map((iteam) => (
          <tbody className="">
            <tr className="text-center border border-black">
              <td>{iteam.Round}</td>
              <td>{iteam.roundName}</td>
              <td>{new Date(iteam.Final).toLocaleDateString("en-GB").replace(/\//g, "-")}</td>
              <td>₹ {iteam.Price}</td>
            </tr>
          </tbody>
        ))}

      </table>
        <div className="flex w-4/12 justify-between m-3 mt-7">
      <p className="text-center font-extrabold text-xl">
        May{" "}
      </p>
        <Link to={"/APTlabUserPannel/edit/calender/#may"}>
          <button className="text-lg border-2 border-green-400 bg-green-500 w-20 font-bold rounded-md text-white Calender-table">
            Edit
          </button>
        </Link>
        </div>
      <table className="w-10/12">
        <thead>
          <tr className="text-center border-2 border-red-600">
            <th>PT Round Nos.</th>
            <th>Matrix / Product</th>
            <th>Registration Closing Date</th>
            <th>Advance Fee Amount</th>
          </tr>
        </thead>

        {Calender.may.map((iteam) => (
          <tbody className="">
            <tr className="text-center border border-black">
              <td>{iteam.Round}</td>
              <td>{iteam.roundName}</td>
              <td>{new Date(iteam.Final).toLocaleDateString("en-GB").replace(/\//g, "-")}</td>
              <td>₹ {iteam.Price}</td>
            </tr>
          </tbody>
        ))}

      </table>
        <div className="flex w-4/12 justify-between m-3 mt-7">
      <p className="text-center font-extrabold text-xl">
        June{" "}
      </p>
        <Link to={"/APTlabUserPannel/edit/calender/#june"}>
          <button className="text-lg border-2 border-green-400 bg-green-500 w-20 font-bold rounded-md text-white Calender-table">
            Edit
          </button>
        </Link>
        </div>
      <table className="w-10/12">
        <thead>
          <tr className="text-center border-2 border-red-600">
            <th>PT Round Nos.</th>
            <th>Matrix / Product</th>
            <th>Registration Closing Date</th>
            <th>Advance Fee Amount</th>
          </tr>
        </thead>

        {Calender.june.map((iteam) => (
          <tbody className="">
            <tr className="text-center border border-black">
              <td>{iteam.Round}</td>
              <td>{iteam.roundName}</td>
              <td>{new Date(iteam.Final).toLocaleDateString("en-GB").replace(/\//g, "-")}</td>
              <td>₹ {iteam.Price}</td>
            </tr>
          </tbody>
        ))}

      </table>
        <div className="flex w-4/12 justify-between m-3 mt-7">
      <p className="text-center font-extrabold text-xl">
        July{" "}
      </p>
        <Link to={"/APTlabUserPannel/edit/calender/#july"}>
          <button className="text-lg border-2 border-green-400 bg-green-500 w-20 font-bold rounded-md text-white Calender-table">
            Edit
          </button>
        </Link>
        </div>
      <table className="w-10/12">
        <thead>
          <tr className="text-center border-2 border-red-600">
            <th>PT Round Nos.</th>
            <th>Matrix / Product</th>
            <th>Registration Closing Date</th>
            <th>Advance Fee Amount</th>
          </tr>
        </thead>

        {Calender.july.map((iteam) => (
          <tbody className="">
            <tr className="text-center border border-black">
              <td>{iteam.Round}</td>
              <td>{iteam.roundName}</td>
              <td>{new Date(iteam.Final).toLocaleDateString("en-GB").replace(/\//g, "-")}</td>
              <td>₹ {iteam.Price}</td>
            </tr>
          </tbody>
        ))}

      </table>
        <div className="flex w-4/12 justify-between m-3 mt-7">
      <p className="text-center font-extrabold text-xl">
        August{" "}
      </p>
        <Link to={"/APTlabUserPannel/edit/calender/#august"}>
          <button className="text-lg border-2 border-green-400 bg-green-500 w-20 font-bold rounded-md text-white Calender-table">
            Edit
          </button>
        </Link>
        </div>
      <table className="w-10/12">
        <thead>
          <tr className="text-center border-2 border-red-600">
            <th>PT Round Nos.</th>
            <th>Matrix / Product</th>
            <th>Registration Closing Date</th>
            <th>Advance Fee Amount</th>
          </tr>
        </thead>

        {Calender.august.map((iteam) => (
          <tbody className="">
            <tr className="text-center border border-black">
              <td>{iteam.Round}</td>
              <td>{iteam.roundName}</td>
              <td>{new Date(iteam.Final).toLocaleDateString("en-GB").replace(/\//g, "-")}</td>
              <td>₹ {iteam.Price}</td>
            </tr>
          </tbody>
        ))}

      </table>
        <div className="flex w-4/12 justify-between m-3 mt-7">
      <p className="text-center font-extrabold text-xl">
        September{" "}
      </p>
        <Link to={"/APTlabUserPannel/edit/calender/#september"}>
          <button className="text-lg border-2 border-green-400 bg-green-500 w-20 font-bold rounded-md text-white Calender-table">
            Edit
          </button>
        </Link>
        </div>
      <table className="w-10/12">
        <thead>
          <tr className="text-center border-2 border-red-600">
            <th>PT Round Nos.</th>
            <th>Matrix / Product</th>
            <th>Registration Closing Date</th>
            <th>Advance Fee Amount</th>
          </tr>
        </thead>

        {Calender.september.map((iteam) => (
          <tbody className="">
            <tr className="text-center border border-black">
              <td>{iteam.Round}</td>
              <td>{iteam.roundName}</td>
              <td>{new Date(iteam.Final).toLocaleDateString("en-GB").replace(/\//g, "-")}</td>
              <td>₹ {iteam.Price}</td>
            </tr>
          </tbody>
        ))}

      </table>
        <div className="flex w-4/12 justify-between m-3 mt-7">
      <p className="text-center font-extrabold text-xl">
        October{" "}
      </p>
        <Link to={"/APTlabUserPannel/edit/calender/#october"}>
          <button className="text-lg border-2 border-green-400 bg-green-500 w-20 font-bold rounded-md text-white Calender-table">
            Edit
          </button>
        </Link>
        </div>
      <table className="w-10/12">
        <thead>
          <tr className="text-center border-2 border-red-600">
            <th>PT Round Nos.</th>
            <th>Matrix / Product</th>
            <th>Registration Closing Date</th>
            <th>Advance Fee Amount</th>
          </tr>
        </thead>

        {Calender.october.map((iteam) => (
          <tbody className="">
            <tr className="text-center border border-black">
              <td>{iteam.Round}</td>
              <td>{iteam.roundName}</td>
              <td>{new Date(iteam.Final).toLocaleDateString("en-GB").replace(/\//g, "-")}</td>
              <td>₹ {iteam.Price}</td>
            </tr>
          </tbody>
        ))}

      </table>
        <div className="flex w-4/12 justify-between m-3 mt-7">
      <p className="text-center font-extrabold text-xl">
        November{" "}
      </p>
        <Link to={"/APTlabUserPannel/edit/calender/#november"}>
          <button className="text-lg border-2 border-green-400 bg-green-500 w-20 font-bold rounded-md text-white Calender-table">
            Edit
          </button>
        </Link>
        </div>
      <table className="w-10/12">
        <thead>
          <tr className="text-center border-2 border-red-600">
            <th>PT Round Nos.</th>
            <th>Matrix / Product</th>
            <th>Registration Closing Date</th>
            <th>Advance Fee Amount</th>
          </tr>
        </thead>

        {Calender.november.map((iteam) => (
          <tbody className="">
            <tr className="text-center border border-black">
              <td>{iteam.Round}</td>
              <td>{iteam.roundName}</td>
              <td>{new Date(iteam.Final).toLocaleDateString("en-GB").replace(/\//g, "-")}</td>
              <td>₹ {iteam.Price}</td>
            </tr>
          </tbody>
        ))}

      </table>
        <div className="flex w-4/12 justify-between m-3 mt-7">
      <p className="text-center font-extrabold text-xl">
        December{" "}
      </p>
        <Link to={"/APTlabUserPannel/edit/calender/#december"}>
          <button className="text-lg border-2 border-green-400 bg-green-500 w-20 font-bold rounded-md text-white Calender-table">
            Edit
          </button>
        </Link>
        </div>
      <table className="w-10/12">
        <thead>
          <tr className="text-center border-2 border-red-600">
            <th>PT Round Nos.</th>
            <th>Matrix / Product</th>
            <th>Registration Closing Date</th>
            <th>Advance Fee Amount</th>
          </tr>
        </thead>

        {Calender.december.map((iteam) => (
          <tbody className="">
            <tr className="text-center border border-black">
              <td>{iteam.Round}</td>
              <td>{iteam.roundName}</td>
              <td>{new Date(iteam.Final).toLocaleDateString("en-GB").replace(/\//g, "-")}</td>
              <td>₹ {iteam.Price}</td>
            </tr>
          </tbody>
        ))}
      </table>
      <div className="flex space-x-96">
        <button
          onClick={handleClick}
          disabled={btnDisable}
          className="text-lg mt-3 p-2 border-2 border-red-700 w-36 bg-red-500 font-bold rounded-md text-white"
        >
          {btnDisable ? "Loading..." : "Final Submit"}
        </button>
      </div>
      {navigate && <Navigate to={"/APTlabUserPannel/display"} />}
    </div>
  );
}

export default EditPage;






// import React, { useState } from "react";
// import { useSelector, useDispatch } from "react-redux";
// import axios from "../../api/axios.js";
// import {useLocation, Navigate } from "react-router-dom";
// import toast from "react-hot-toast";
// import {resetCalender} from "../../redux/CalenderSclice.js"
// import EditComponent from "./EditComponent.jsx";

// function EditPage() {
//   const dispatch = useDispatch()
//   const Calender = useSelector((state) => state.Calender);
//   const [navigate, setnavigate] = useState(false);
//   const [btnDisable, setBtnDisable] = useState(false);
//   console.log(Calender.january);
//   async function handleClick() {
//     try {
//       setBtnDisable(true);
//       const response = await axios.post("/inputCalender", Calender);
//       toast.success(response.data.message);
//       console.log(response);
//       setnavigate(true);
//       dispatch(resetCalender())
//     } catch (error) {
//       toast.error(error.response.data.message);
//       console.log(error);
//     } finally {
//       setBtnDisable(false);
//     }
//   }
//   return (
//     <div className="h-screen flex flex-col items-center">
//       <div className="w-full">

//       <EditComponent Calender={Calender} />
//       </div>
//       <div className="flex space-x-96">
//         <button
//           onClick={handleClick}
//           disabled={btnDisable}
//           className="text-lg mt-3 p-2 border-2 border-red-700 w-36 bg-red-500 font-bold rounded-md text-white"
//         >
//           {btnDisable ? "Loading..." : "Final Submit"}
//         </button>
//       </div>
//       {navigate && <Navigate to={"/calender/#1"} />}
//     </div>
//   );
// }

// export default EditPage;
