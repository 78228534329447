
// import React, { useState } from "react";
// import "./FeedbackForm.css";

// const FeedbackForm = () => {
//   const [rating, setRating] = useState(null);
//   const [feedback, setFeedback] = useState("");
//   const [submitted, setSubmitted] = useState(false);

//   const emojis = [
//     { label: 2, emoji: "😠" },
//     { label: 4, emoji: "😞" },
//     { label: 6, emoji: "😐" },
//     { label: 8, emoji: "😊" },
//     { label: 10, emoji: "🤩" },
//   ];
//   const meetingPtReq = [
//     {
//       uniqueID: 1,
//       question: "How do you rate the PT Matrix?",
//     },
//     {
//       uniqueID: 2,
//       question: "Meeting PT requirements",
//     },
//     {
//       uniqueID: 3,
//       question: "Condition/Packaging of PT sample received",
//     },
//     {
//       uniqueID: 4,
//       question: "On time Delivery of PT Report",
//     },
//     {
//       uniqueID: 5,
//       question: "Content of PT report useful and easy to understand",
//     },
//   ];
//   const overallSatisfaction = [
//     {
//       uniqueID: 6,
//       question: "Timely Resolution of your query if any",
//     },
//   ];
//   const service = [
//     {
//       uniqueID: 7,
//       question: "How do you rate the Overall QUALITY of our Service",
//     },
//   ];

//   const handleSubmit = (e) => {
//     e.preventDefault();
//     console.log("Rating:", rating);
//     console.log("Feedback:", feedback);
//     setSubmitted(true);
//   };

//   return (
//     <>
//       {submitted ? (
//         <div className="thank-you-message">
//           <h2>Thank you for your feedback! 🎉</h2>
//         </div>
//       ) : (
//         <>
//           <div className="feedback-form-container ">
//             <form onSubmit={handleSubmit} className="feedback-form p-2">
//               <div className="flex justify-around p-4 mb-10 gap-8">
//                 <div className="flex flex-col  w-full">
//                   <input
//                     type="text"
//                     className="border-2 border-black rounded-md p-1 mb-4 w-full"
//                     placeholder="Name of the Laboratory:"
//                   />
//                   <input
//                     type="text"
//                     className="border-2 border-black rounded-md p-1 mb-4 w-full"
//                     placeholder="PT Round Number:"
//                   />
//                   <input
//                     type="text"
//                     className="border-2 border-black rounded-md p-1 mb-4 w-full"
//                     placeholder="Matrix / Product:"
//                   />
//                 </div>
//                 <div className="flex flex-col justify-between w-full">
//                   <input
//                     type="text"
//                     className="border-2 border-black rounded-md p-1 mb-4 w-full"
//                     placeholder="Lab Code:"
//                   />
//                   <input
//                     type="text"
//                     className="border-2 border-black rounded-md p-1 mb-4 w-full"
//                     placeholder="Date:"
//                   />
//                   <input
//                     type="text"
//                     className="border-2 border-black rounded-md p-1 mb-4 w-full"
//                     placeholder="Contact Person Name:"
//                   />
//                 </div>
//               </div>
//               <div className="flex gap-4 justify-around">
//                 <div>
//                   <h2 className="font-bold text-xl">
//                     MEETING PT REQUIREMNETS:
//                   </h2>
//                   {meetingPtReq.map((item) => {
//                     return (
//                       <div key={item.uniqueID} className="flex flex-col gap-4 ">
//                         <p>{item.question}</p>
//                         <div>
//                           {emojis.map((item, index) => (
//                             <button
//                               key={index}
//                               type="button"
//                               className={`emoji-button ${
//                                 rating === item.label ? "selected" : ""
//                               }`}
//                               onClick={() => setRating(item.label)}
//                               aria-label={item.label}
//                             >
//                               {item.emoji}
//                             </button>
//                           ))}
//                         </div>
//                       </div>
//                     );
//                   })}
//                 </div>
//                 <div>
//                   <h2 className="font-bold text-xl">SERVICE:</h2>
//                   {service.map((item) => {
//                     return (
//                       <div
//                         key={item.uniqueID}
//                         className="flex flex-col gajustify-between"
//                       >
//                         <p>{item.question}</p>
//                         <div>
//                           {emojis.map((item, index) => (
//                             <button
//                               key={index}
//                               type="button"
//                               className={`emoji-button ${
//                                 rating === item.label ? "selected" : ""
//                               }`}
//                               onClick={() => setRating(item.label)}
//                               aria-label={item.label}
//                             >
//                               {item.emoji}
//                             </button>
//                           ))}
//                         </div>
//                       </div>
//                     );
//                   })}
//                 </div>
//                 <div>
//                   <h2 className="font-bold text-xl">OVERALL SATISFACTION:</h2>
//                   {overallSatisfaction.map((item) => {
//                     return (
//                       <div
//                         key={item.uniqueID}
//                         className="flex flex-col gap-4 justify-between"
//                       >
//                         <p>{item.question}</p>
//                         <div>
//                           {emojis.map((item, index) => (
//                             <button
//                               key={index}
//                               type="button"
//                               className={`emoji-button ${
//                                 rating === item.label ? "selected" : ""
//                               }`}
//                               onClick={() => setRating(item.label)}
//                               aria-label={item.label}
//                             >
//                               {item.emoji}
//                             </button>
//                           ))}
//                         </div>
//                       </div>
//                     );
//                   })}
//                 </div>
//               </div>

//               <textarea
//                 placeholder="Tell us more about your experience..."
//                 value={feedback}
//                 onChange={(e) => setFeedback(e.target.value)}
//                 className="feedback-textarea"
//               />

//               <button type="submit" className="submit-button">
//                 Submit Feedback
//               </button>
//             </form>
//           </div>
//         </>
//       )}
//     </>
//   );
// };

// export default FeedbackForm;

import React, { useState } from "react";
import "./FeedbackForm.css";
import axios from "../../api/axiosPublic";

const FeedbackForm = () => {
  const [rating, setRating] = useState({
    "1":"",
    "2":"",
    "3":"",
    "4":"",
    "5":"",
    "6":"",
    "7":"",
  });
  const [feedback, setFeedback] = useState("");
  const [submitted, setSubmitted] = useState(false);
  const [labInfo, setLabInfo] = useState({
    labName:"",
    labCode:"",
    date:"",
    ptRound:"",
    matrix:"",
    contactPerson:"",
  });

  const emojis = [
    { label: 2, emoji: "😠" },
    { label: 4, emoji: "😞" },
    { label: 6, emoji: "😐" },
    { label: 8, emoji: "😊" },
    { label: 10, emoji: "🤩" },
  ];

  const meetingPtReq = [
    { uniqueID: 1, question: "How do you rate the PT Matrix?" },
    { uniqueID: 2, question: "Meeting PT requirements" },
    { uniqueID: 3, question: "Condition/Packaging of PT sample received" },
    { uniqueID: 4, question: "On time Delivery of PT Report" },
    { uniqueID: 5, question: "Content of PT report useful and easy to understand" },
  ];

  const overallSatisfaction = [
    { uniqueID: 6, question: "Timely Resolution of your query if any" },
  ];

  const service = [
    { uniqueID: 7, question: "How do you rate the Overall QUALITY of our Service" },
  ];

  const handleRatingChange = (id, value) => {
    setRating((prev) => ({
      ...prev,
      [id]: value,
    }));
  };

  const [errors, setErrors] = useState({});

  const validate = () => {
    const newErrors = {};
    if (!labInfo.labName.trim()) newErrors.labName = "Lab Name is required.";
    if (!labInfo.labCode.trim()) newErrors.labCode = "Lab Code is required.";
    if (!labInfo.ptRound.trim()) newErrors.ptRound = "PT Round Number is required.";
    if (!labInfo.matrix.trim()) newErrors.matrix = "Matrix/Product is required.";
    if (!labInfo.contactPerson.trim()) newErrors.contactPerson = "Contact Person Name is required.";
    return newErrors;
  };

  const handleSubmit = async (e) => {
    e.preventDefault();
    const validationErrors = validate();
    if (Object.keys(validationErrors).length > 0) {
      setErrors(validationErrors);
      return;
    }
    setErrors({}); // Clear errors if validation passes

    const allInfo = {
      formQuestion1: rating["1"].toString(),
      formQuestion2: rating["2"].toString(),
      formQuestion3: rating["3"].toString(),
      formQuestion4: rating["4"].toString(),
      formQuestion5: rating["5"].toString(),
      formQuestion6: rating["6"].toString(),
      formQuestion7: rating["7"].toString(),
      feedback,
      ...labInfo,
    };

    try {
      const someval = await axios.post("/cssform", allInfo);
      console.log("Ratings:", someval);
      setSubmitted(true);
    } catch (error) {
      console.error("Error submitting form:", error);
    }
  };


  return (
    <>
      {submitted ? (
        <div className="thank-you-message">
          <h2>Thank you for your feedback! 🎉</h2>
        </div>
      ) : (
        <div className="feedback-form-container">
        <h2 className="font-bold text-center text-3xl">CSS FORM</h2>
          <form onSubmit={handleSubmit} className="feedback-form">
            <div className="flex justify-around p-4 mb-10 gap-8">
        <div className="flex flex-col w-full">
          <input
            type="text"
            className="border-2 border-black rounded-md p-1 mb-4 w-full"
            placeholder="Name of the Laboratory:"
            onChange={(e) => setLabInfo({ ...labInfo, labName: e.target.value })}
          />
          {errors.labName && <p className="text-red-500 text-sm">{errors.labName}</p>}

          <input
            type="text"
            className="border-2 border-black rounded-md p-1 mb-4 w-full"
            onChange={(e) => setLabInfo({ ...labInfo, ptRound: e.target.value })}
            placeholder="PT Round Number:"
          />
          {errors.ptRound && <p className="text-red-500 text-sm">{errors.ptRound}</p>}

          <input
            type="text"
            className="border-2 border-black rounded-md p-1 mb-4 w-full"
            placeholder="Matrix / Product:"
            onChange={(e) => setLabInfo({ ...labInfo, matrix: e.target.value })}
          />
          {errors.matrix && <p className="text-red-500 text-sm">{errors.matrix}</p>}
        </div>

        <div className="flex flex-col w-full">
          <input
            type="text"
            className="border-2 border-black rounded-md p-1 mb-4 w-full"
            placeholder="Lab Code:"
            onChange={(e) => setLabInfo({ ...labInfo, labCode: e.target.value })}
          />
          {errors.labCode && <p className="text-red-500 text-sm">{errors.labCode}</p>}

          <input
            type="text"
            className="border-2 border-black rounded-md p-1 mb-4 w-full"
            placeholder="Contact Person Name:"
            onChange={(e) => setLabInfo({ ...labInfo, contactPerson: e.target.value })}
          />
          {errors.contactPerson && <p className="text-red-500 text-sm">{errors.contactPerson}</p>}
        </div>
      </div>

            <div className="feedback-sections">
              <div>
                <h2 className="font-bold text-xl">MEETING PT REQUIREMENTS:</h2>
                {meetingPtReq.map((item) => (
                  <div key={item.uniqueID} className="feedback-item">
                    <div className="emoji-rating">
                    <p>{item.question}</p>
                      {emojis.map((emoji) => (
                        <button
                          key={emoji.label}
                          type="button"
                          className={`emoji-button ${
                            rating[item.uniqueID] === emoji.label ? "selected" : ""
                          }`}
                          onClick={() => handleRatingChange(item.uniqueID, emoji.label)}
                          aria-label={emoji.label}
                        >
                          {emoji.emoji}
                        </button>
                      ))}
                    </div>
                  </div>
                ))}
              </div>

              <div>
                <h2 className="font-bold text-xl">SERVICE:</h2>
                {service.map((item) => (
                  <div key={item.uniqueID} className="feedback-item">
                    <div className="emoji-rating">
                    <p>{item.question}</p>
                      {emojis.map((emoji) => (
                        <button
                          key={emoji.label}
                          type="button"
                          className={`emoji-button ${
                            rating[item.uniqueID] === emoji.label ? "selected" : ""
                          }`}
                          onClick={() => handleRatingChange(item.uniqueID, emoji.label)}
                          aria-label={emoji.label}
                        >
                          {emoji.emoji}
                        </button>
                      ))}
                    </div>
                  </div>
                ))}
              </div>

              <div>
                <h2 className="font-bold text-xl">OVERALL SATISFACTION:</h2>
                {overallSatisfaction.map((item) => (
                  <div key={item.uniqueID} className="feedback-item">
                    <div className="emoji-rating">
                    <p>{item.question}</p>
                      {emojis.map((emoji) => (
                        <button
                          key={emoji.label}
                          type="button"
                          className={`emoji-button ${
                            rating[item.uniqueID] === emoji.label ? "selected" : ""
                          }`}
                          onClick={() => handleRatingChange(item.uniqueID, emoji.label)}
                          aria-label={emoji.label}
                        >
                          {emoji.emoji}
                        </button>
                      ))}
                    </div>
                  </div>
                ))}
              </div>
            </div>

            <textarea
              placeholder="Tell us more about your experience..."
              value={feedback}
              onChange={(e) => setFeedback(e.target.value)}
              className="feedback-textarea"
            />

            <button type="submit" className="submit-button">
              Submit Feedback
            </button>
          </form>
        </div>
      )}
    </>
  );
};

export default FeedbackForm;
