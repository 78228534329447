import "./registration.css"
import React from 'react'
import {useForm} from "react-hook-form"
import axiosHandler from "../../../api/axios"
import toast from "react-hot-toast"

function APTRegistration() {
    const {register,handleSubmit,reset,formState: { errors,isSubmitting },setError} = useForm()
   
    async function methodSubmit(data) {
      try {
        if (data.password !== data.confirmPassword) {
         return setError("root",{
            message:"Password And Conform Password should Match"
          })
        }
        const responce= await axiosHandler.post('/register',data)
       
        
          toast.success(responce.data.message)
        reset()
      } catch (error) {
        console.log(error)
        
        toast.error(error.response.data.message)
        reset()
      }
    }
   
  return (
    <main className="h-full grid grid-cols-2 justify-center bg-white  items-center ">
      <section className="h-full flex registration-main">
      <form className="w-10/12 rounded-md p-1 m-3 bg-[#ffffff34] flex flex-col justify-center items-center" onSubmit={handleSubmit(methodSubmit)}>
        <h1 className="text-4xl font-semibold text-blue-50">Registration</h1>
        <input {...register("userName",{
            required:"User Name is require"
        })} className="w-9/12 rounded p-1 border border-blue-400 mt-[5%] " type="text" placeholder='User Name' />
        {errors.userName && <div className='text-lg font-bold text-red-600'>{errors.userName.message}</div>}
        
        
        <input {...register("email",{
          required:"Email is require"
        })} className="w-9/12 rounded p-1 border border-blue-400 mt-[5%] " type="email" placeholder='Email' />
        {errors.email && <div className='text-lg font-bold text-red-600'>{errors.email.message}</div>}
        
        
        <input {...register("phone",{
          required:"Phone Number is require"
        })} className="w-9/12 rounded p-1 border border-blue-400 mt-[5%] " type="number" placeholder='Phone Number' />
        {errors.userName && <div className='text-lg font-bold text-red-600'>{errors.userName.message}</div>}
        
        
        <input {...register("password",{
          required:"Password is require"
        })} className="w-9/12 rounded p-1 border border-blue-400 mt-[5%] " type="password" placeholder='Password' />
        {errors.phone && <div className='text-lg font-bold text-red-600'>{errors.phone.message}</div>}
        
        
        <input {...register("confirmPassword",{
          required:"Confirm Password is require"
        })} className="w-9/12 rounded p-1 border border-blue-400 mt-[5%] " type="password" placeholder='Confirm Password' />
        {errors.confirmPassword && <div className='text-lg font-bold text-red-600'>{errors.confirmPassword.message}</div>}
        
       
  <button type="submit" className="button-PT-registration" disabled={isSubmitting}>
    {isSubmitting?"Loading...": "Submit"}
  </button>

        {errors.root && <div className='text-lg font-bold text-red-600'>{errors.root.message}</div>}
      </form>

      </section>
      <div className="flex flex-col space-y-5 items-center z-10">
        <img src="/APT.png" className="mix-blend-color-burn " alt="Apt" />
        {/* <img src="https://aashvipt.com/wp-content/uploads/2023/01/hm1.jpg" className="w-4/12" alt="something" /> */}
      </div>
    </main>
  )
}

export default APTRegistration
