import React, { useEffect, useState } from "react";
import axios from "../../../api/axios";
import { Link, useNavigate } from "react-router-dom";
import {
  setYear,
  setJanuary,
  setFebruary,
  setMarch,
  setApril,
  setMay,
  setJune,
  setJuly,
  setAugust,
  setSeptember,
  setOctober,
  setNovember,
  setDecember,
  setIsupdate
} from "../../../redux/CalenderSclice.js";
import axiosHandler from "../../../api/axios";
import { toast } from "react-hot-toast";
import { useDispatch } from "react-redux";
import { Popup } from "../../popup/popup.jsx";
import {resetCalender} from "../../../redux/CalenderSclice.js"
function DisplayCalender() {
  const dispatch = useDispatch();
  const navigate = useNavigate()
  const [calenderData, setCalenderData] = useState([]);
  const [DeleteCalender, setDeleteCalender] = useState(false);
  const [years,setYears] = useState(2000)
  useEffect(() => {
    (async function () {
      try {
        dispatch(resetCalender())
        const response = await axios.get("/getAllCalender");
        console.log(response.data.data); // Log the response
        setCalenderData(Array.isArray(response.data.data) ? response.data.data : []); // Safeguard against undefined or null
      } catch (error) {
        console.error("Error fetching calendar data:", error);
        setCalenderData([]); // Set to an empty array on error
      }
    })();
  }, []);
  
  async function onupdate(year) {
    try {
      const response = await axiosHandler.post("/registration", { year });
      const formatedDataArray = response.data.data;
      dispatch(setYear(formatedDataArray.year));
      dispatch(setJanuary(formatedDataArray.january));
      dispatch(setFebruary(formatedDataArray.february));
      dispatch(setMarch(formatedDataArray.march));
      dispatch(setApril(formatedDataArray.april));
      dispatch(setMay(formatedDataArray.may));
      dispatch(setJune(formatedDataArray.june));
      dispatch(setJuly(formatedDataArray.july));
      dispatch(setAugust(formatedDataArray.august));
      dispatch(setSeptember(formatedDataArray.september));
      dispatch(setOctober(formatedDataArray.october));
      dispatch(setNovember(formatedDataArray.november));
      dispatch(setDecember(formatedDataArray.december));
      dispatch(setIsupdate(true));
      
      navigate("/APTlabUserPannel/edit")
    } catch (error) {
      toast.error(error.response.data.message);
    }
  }
  
  async function onDelete(year) {
    setYears(year)
    setDeleteCalender(true)
  } 
  
  async function popupMethod(year) {
    try {
      const response =  await axiosHandler.delete("/deleteCalender",{data:{year: year}})

      toast.success(response.data.message)
      setDeleteCalender(false)
      
  } catch (error) {
    toast.error(error.response.data.message)
  }
  }
  
  return (
    <main className="flex flex-col justify-center items-center">
      <h1 className="text-2xl font-bold">Calender Controller</h1>

      <table className="Calender-table w-10/12 mt-8 rounded">
        <thead>
          <tr className="border-b-2 border-black">
            <th>Calender Year</th>
            <th>Created Date</th>
            <th>Last Updated Date</th>
            <th>Options</th>
          </tr>
        </thead>
        <tbody className="space-y-2 m-2 gap-y-3">
          {calenderData.map((items, index) => (
            <tr key={index} className="">
              <td className="text-center font-bold  p-3">{items.year}</td>
              <td className="text-center font-bold ">
                {new Date(items.createdAt).toLocaleDateString("en-GB").replace(/\//g, "-")}
              </td>
              <td className="text-center font-bold ">
                {new Date(items.updatedAt).toLocaleDateString("en-GB").replace(/\//g, "-")}
              </td>
              <td className="flex items-center justify-center text-center m-1 space-x-2">
                <button
                  className="text-white font-serif p-2 pl-2 text-sm  pr-2 w-1/4 h-3/6 bg-green-500 Calender-table rounded-md font-bold"
                  onClick={() => {
                    onupdate(items.year);
                  }}
                  >
                  View & Edit{" "}
                </button>{" "}
                <button onClick={()=>{onDelete(items.year)}} className=" text-white font-serif cursor-pointer rounded-md font-bold">
                  <img src="/delete.png" className="h-12" alt="delete" />
                </button>
              </td>
            </tr>
          ))}
        </tbody>
      </table>
          {DeleteCalender ? <Popup text={`Do you want to delete the Calender of Year `} Delete={()=>{popupMethod(years)}} noDelete={()=>{setDeleteCalender(false)}}  year={years}  /> : null}
      <Link to={"/APTlabUserPannel/calender/#1"} className="Calender-table w-20 display-addButton  text-7xl m-4 bg-[#0026ff47] rounded-md font-thin flex text-white text-center  "> <p className="pb-2 w-20">+</p> </Link>

      
    </main>
  );
}

export default DisplayCalender;
