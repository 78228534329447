import React, { useEffect, useState } from "react";
import { useLocation, Link, Navigate } from "react-router-dom";
import { useDispatch, useSelector } from "react-redux";
import { useForm } from "react-hook-form";
import "./calender.css";
import {
  setYear,
  setJanuary,
  setFebruary,
  setMarch,
  setApril,
  setMay,
  setJune,
  setJuly,
  setAugust,
  setSeptember,
  setOctober,
  setNovember,
  setDecember,
} from "../../../redux/CalenderSclice.js";

function CalenderInput() {
  const location = useLocation();
  const [month, setMonth] = useState("jan");
  const [monthName, setMonthName] = useState(location.hash.replace(/^#/, "").length<3?"january":location.hash.replace(/^#/, ""));
  const [editPage, setEditPage] = useState(false);
  const [btnSubmit, setBtnSubmit] = useState(false);
  
  const dispatch = useDispatch();
  useEffect(() => {

    
    switch (parseInt(location.hash.replace(/^#/, ""))) {
      case 1 || "january" :
        setMonth("jan");
        
        setMonthName("January");
        setBtnSubmit(false);
        break;
        case 2 || "february":
          setMonth("feb");
          
        setMonthName("February");
        setBtnSubmit(false);
        break;
      case 3 || "march":
        setMonth("mar");
        setMonthName("March");
        setBtnSubmit(false);
        break;
      case 4 || "april":
        setMonth("apr");
        setMonthName("April");
        setBtnSubmit(false);
        break;
      case 5 || "may":
        setMonth("may");
        setMonthName("May");
        setBtnSubmit(false);
        break;
      case 6 || "june":
        setMonth("jun");
        setMonthName("June");
        setBtnSubmit(false);
        break;
      case 7|| "july":
        setMonth("jul");
        setMonthName("July");
        setBtnSubmit(false);
        break;
      case 8 || "august":
        setMonth("aug");
        setMonthName("August");
        setBtnSubmit(false);
        break;
      case 9|| "september":
        setMonth("sep");
        setMonthName("September");
        setBtnSubmit(false);
        break;
      case 10 || "october":
        setMonth("oct");
        setMonthName("October");
        setBtnSubmit(false);
        break;
      case 11 || "november":
        setMonth("nov");
        setMonthName("November");
        setBtnSubmit(false);
        break;
      case 12 || "december":
        setMonth("dec");
        setMonthName("December");
        setBtnSubmit(false);
        break;
      case 13:
        setEditPage(true);
        break;
      default:
        // Handle any other cases if needed
        break;
    }
  }, [location.hash]);

  console.log("monthName", monthName)
  // let myMonth = monthName.toLowerCase()

    const CalendarData = useSelector(
      (state) => state.Calender
    );
    const Calendar = CalendarData[monthName.toLowerCase()]
    
  console.log("Calendar Data", Calendar);
  const { register, handleSubmit, reset } = useForm({
    defaultValues: {
      [`${month}-round-0`]: Calendar[0]?.Round || "",
      [`${month}-round-1`]: Calendar[1]?.Round || "",
      [`${month}-round-2`]:Calendar[2]?.Round || '',
      [`${month}-round-3`]:Calendar[3]?.Round || '',
      [`${month}-round-4`]:Calendar[4]?.Round || '',
      [`${month}-round-5`]:Calendar[5]?.Round || '',
      [`${month}-round-6`]:Calendar[6]?.Round || '',
      [`${month}-round-7`]:Calendar[7]?.Round || '',
      [`${month}-round-8`]:Calendar[8]?.Round || '',
      [`${month}-round-9`]:Calendar[9]?.Round || '',
      [`${month}-roundName-0`]: Calendar[0]?.roundName || "",
      [`${month}-roundName-1`]: Calendar[1]?.roundName || "",
      [`${month}-roundName-2`]:Calendar[2]?.roundName || '',
      [`${month}-roundName-3`]:Calendar[3]?.roundName || '',
      [`${month}-roundName-4`]:Calendar[4]?.roundName || '',
      [`${month}-roundName-5`]:Calendar[5]?.roundName || '',
      [`${month}-roundName-6`]:Calendar[6]?.roundName || '',
      [`${month}-roundName-7`]:Calendar[7]?.roundName || '',
      [`${month}-roundName-8`]:Calendar[8]?.roundName || '',
      [`${month}-roundName-9`]:Calendar[9]?.roundName || '',
      [`${month}-price-0`]: Calendar[0]?.Price || "",
      [`${month}-price-1`]: Calendar[1]?.Price || "",
      [`${month}-price-2`]:Calendar[2]?.Price || '',
      [`${month}-price-3`]:Calendar[3]?.Price || '',
      [`${month}-price-4`]:Calendar[4]?.Price || '',
      [`${month}-price-5`]:Calendar[5]?.Price || '',
      [`${month}-price-6`]:Calendar[6]?.Price || '',
      [`${month}-price-7`]:Calendar[7]?.Price || '',
      [`${month}-price-8`]:Calendar[8]?.Price || '',
      [`${month}-price-9`]:Calendar[9]?.Price || '',
      [`${month}-final-0`]: Calendar[0]?.Final || "",
      [`${month}-final-1`]: Calendar[1]?.Final || "",
      [`${month}-final-2`]:Calendar[2]?.Final || '',
      [`${month}-final-3`]:Calendar[3]?.Final || '',
      [`${month}-final-4`]:Calendar[4]?.Final || '',
      [`${month}-final-5`]:Calendar[5]?.Final || '',
      [`${month}-final-6`]:Calendar[6]?.Final || '',
      [`${month}-final-7`]:Calendar[7]?.Final || '',
      [`${month}-final-8`]:Calendar[8]?.Final || '',
      [`${month}-final-9`]:Calendar[9]?.Final || '',
    },
  });

  function methodSubmit(data) {
    // Prevent the browser from reloading the page
    console.log(data);
    try {
      // Read the form data

      const formJson = data;
      const formatedDataArray = [];

      const dataCount = Object.keys(formJson).filter((key) =>
        key.startsWith(`${month}-round-`)
      ).length;

      for (let i = 0; i < dataCount; i++) {
        const roundKey = `${month}-round-${i}`;
        const priceKey = `${month}-price-${i}`;
        const startKey = `${month}-roundName-${i}`;
        const finalKey = `${month}-final-${i}`;

        if (
          formJson[roundKey] &&
          formJson[priceKey] &&
          formJson[startKey] &&
          formJson[finalKey]
        ) {
          let formatedData = {};

          formatedData["Round"] = formJson[roundKey];
          formatedData["Price"] = formJson[priceKey];
          formatedData["roundName"] = formJson[startKey];
          formatedData["Final"] = formJson[finalKey];

          formatedDataArray.push(formatedData);
        }
      }
      if(location.hash.replace(/^#/, "").length>2){
        switch (location.hash.replace(/^#/, "").toLowerCase()){
          case "january":
            dispatch(setJanuary(formatedDataArray));
            dispatch(setYear(formJson.year));
            break;
          case "february":
            dispatch(setFebruary(formatedDataArray));
            break;
          case "march":
            dispatch(setMarch(formatedDataArray));
            break;
          case "april":
            dispatch(setApril(formatedDataArray));
            break;
          case "may":
            dispatch(setMay(formatedDataArray));
            break;
          case "june":
            dispatch(setJune(formatedDataArray));
            break;
          case "july":
            dispatch(setJuly(formatedDataArray));
            break;
          case "august":
            dispatch(setAugust(formatedDataArray));
            break;
          case "september":
            dispatch(setSeptember(formatedDataArray));
            break;
          case "october":
            dispatch(setOctober(formatedDataArray));
            break;
          case "november":
            dispatch(setNovember(formatedDataArray));
            break;
          case "december":
            dispatch(setDecember(formatedDataArray));
            break;
  
          default:
            // Handle any other cases if needed
            break;
        }
      }else{

      
      switch (parseInt(location.hash.replace(/^#/, ""))) {
        case 1:
          dispatch(setJanuary(formatedDataArray));
          dispatch(setYear(formJson.year));
          break;
        case 2:
          dispatch(setFebruary(formatedDataArray));
          break;
        case 3:
          dispatch(setMarch(formatedDataArray));
          break;
        case 4:
          dispatch(setApril(formatedDataArray));
          break;
        case 5:
          dispatch(setMay(formatedDataArray));
          break;
        case 6:
          dispatch(setJune(formatedDataArray));
          break;
        case 7:
          dispatch(setJuly(formatedDataArray));
          break;
        case 8:
          dispatch(setAugust(formatedDataArray));
          break;
        case 9:
          dispatch(setSeptember(formatedDataArray));
          break;
        case 10:
          dispatch(setOctober(formatedDataArray));
          break;
        case 11:
          dispatch(setNovember(formatedDataArray));
          break;
        case 12:
          dispatch(setDecember(formatedDataArray));
          break;

        default:
          // Handle any other cases if needed
          break;
      }
    }
      setBtnSubmit(true);
      //  setNextPage(true)
      reset();
      if(location.hash.replace(/^#/, "").length>2){
        setEditPage(true)
      }

    } catch (error) {
      console.log(error);
    } finally {
     
    }
  }

  return (
    <div className="h-screen flex justify-center registration-background">
      <form onSubmit={handleSubmit(methodSubmit)} className=" flex flex-col">
        <div className="flex justify-center ">
          <span className="text-center font-bold mt-10 mb-10 text-2xl">
            {monthName.toUpperCase()}
          </span>
          {btnSubmit && (
            <svg
              className="mt-10 mb-10"
              xmlns="http://www.w3.org/2000/svg"
              width="30"
              height="40"
              viewBox="0 0 48 48"
            >
              <path
                fill="#c8e6c9"
                d="M44,24c0,11-9,20-20,20S4,35,4,24S13,4,24,4S44,13,44,24z"
              ></path>
              <polyline
                fill="none"
                stroke="#4caf50"
                strokeMiterlimit="10"
                strokeWidth="4"
                points="14,24 21,31 36,16"
              ></polyline>
            </svg>
          )}
        </div>
        <input
          name="year"
          type="text"
          placeholder="Year"
          defaultValue={CalendarData.year}
          {...register("year")}
          disabled={!(location.hash === "#january" || location.hash === "#1")}
          className={`border-2 Calender-table shadow-md sha p-1 rounded border-black self-center mb-2 ${
            !(location.hash === "#january" || location.hash === "#1")
              ? "sr-only"
              : ""
          }`}
        />

        <table className="w-[1000px] Calender-table rounded">
          <tbody>
            <tr className="space-x-4 ">
              <td className="font-bold">PT Round Nos.</td>
              <td className="font-bold">Matrix / Product</td>
              <td className="font-bold">Registration Closing Date</td>
              <td className="font-bold">Advance Fee Amount</td>
            </tr>
          </tbody>
          {[...Array(10)].map((_, i) => (
            <tbody key={i} className="space-x-4">
              <tr className="space-x-4 border border-black">
                <td>
                  <input
                    name={`${month}-round-${i}`}
                    className="text-start bg-sky-100 p-1 rounded w-full text-black "
                    type="text"
                    {...register(`${month}-round-${i}`)}
                    placeholder="Round"
                    defaultValue={Calendar?.Round}
                  />
                </td>
                <td>
                  <input
                    name={`${month}-roundName-${i}`}
                    className="text-start bg-sky-100 p-1 rounded w-full text-black"
                    type="text"
                    {...register(`${month}-roundName-${i}`)}
                    placeholder="Round Name"
                  />
                </td>
                <td>
                  <input
                    name={`${month}-final-${i}`}
                    className="text-start bg-sky-100 p-1 rounded w-full text-black"
                    type="date"
                    // defaultValue={setTimeout(() => {
                    //   // console.log(Calendar[i]?.Final)
                    //  return Calendar[i]?.Final
                    // }, 500)}
                    {...register(`${month}-final-${i}`)}
                    placeholder="Registration Closing Date"
                  />
                </td>
                <td>
                  <input
                    name={`${month}-price-${i}`}
                    className="text-start bg-sky-100 p-1 rounded w-full text-black"
                    type="text"
                    defaultValue={Calendar?.Price}
                    {...register(`${month}-price-${i}`)}
                    placeholder="Price"
                  />
                </td>
              </tr>
            </tbody>
          ))}
        </table>
        <div className="flex space-x-4">

        <Link to={`/APTlabUserPannel/calender/#${parseInt(location.hash.slice(1)) - 1}`}>
          <button
            className={`${
              location.hash === "#1" || location.hash.replace(/^#/, "").length>2 ? "sr-only" : ""
            } text-lg mt-3 Calender-table p-2 border-2 border-green-700 bg-green-500 w-36 font-bold rounded-md text-white`}
            >
            Previous
          </button>
        </Link>
        <button
          type="submit"
          className="text-lg mt-3 Calender-table p-2 border-2 border-red-700 bg-red-500 w-36 font-bold rounded-md text-white"
          >
          "Submit"
        </button>
        <Link to={`/APTlabUserPannel/calender/#${parseInt(location.hash.slice(1)) + 1}`}>
          <button className={`${location.hash.replace(/^#/, "").length<3 ? "" : "sr-only"} text-lg mt-3 Calender-table p-2 border-2 border-green-700 bg-green-500  w-36 font-bold rounded-md text-white`}>
            Next
          </button>
        </Link>
          </div>
      </form>
      {editPage && <Navigate to={"/APTlabUserPannel/edit"} />}
      
    </div>
  );
}

export default CalenderInput;
