import * as XLSX from 'xlsx';

export const writeToJsonExcel = (data) => {
  // Create a map to store roundNames and corresponding lab details
  const roundMap = {};
  data.forEach(lab => {
    lab.round.forEach(round => {
      if (!roundMap[round.roundName]) {
        roundMap[round.roundName] = [];
      }
      roundMap[round.roundName].push({
        labID: lab._id,
        labName: lab.labName,
        labAddress: lab.labAddress.replace(/\n/g, ' '),
        labEmail: lab.labEmail,
        labContact: lab.labContact,
        labContactNo: lab.labContactNo,
        labGstin: lab.labGstin,
        labNabl: lab.labNabl,
        paymentMethod: lab.paymentMethod,
        createdAt: lab.createdAt,
        roundPrice: round.roundPrice,
        roundMatrix: round.roundMatrix,
        rounds: lab.round
      });
    });
  });

  console.log(roundMap);
  
  // Sort the round names
  const sortedRoundNames = Object.keys(roundMap).sort((a, b) => {
    const numA = parseInt(a.split('-')[1], 10);
    const numB = parseInt(b.split('-')[1], 10);
    return numA - numB; // Sort in ascending order
  });

  console.log(sortedRoundNames);

  // Create an array from the sorted round names
  const excelData = [];
  sortedRoundNames.forEach(roundName => {
    excelData.push([roundName, roundMap[roundName][0].roundMatrix, `${roundMap[roundName][0].roundPrice} + 18% GST`]); // Add round name as a new row
    excelData.push([
      "Registration ID",
      "Lab Name & Address",
      "Contact Person & Tel Nos",
      "E-mail",
      "PT Registration form Received Date",
      "Enrolled for PT Rounds as per PT registration form only",
      "Payment Details",
      "GST Num",
      "NABL Certificate Num:"
    ]);

    roundMap[roundName].forEach(lab => {
      console.log("lab", lab);
      const labRound = lab.rounds.map(item => item.roundName);
      
      // Format rounds with newline for better readability
      const modifiedArray = labRound.map((item, index) => {
        if (index > 0) {
          return `\n${item},`; // Add newline before elements starting from index 1
        }
        return `${item},`; // Keep the first element as it is
      });
      const resultString = modifiedArray.join('');
      const emails = lab.labEmail
      .split(/[\s,]+/) // Split by space or comma
      .map(email => email.trim()) // Remove extra spaces
      .filter(email => email.length > 0); // Remove empty values

  // Join the emails back with a comma if more than one, or single email
  const formattedEmails = emails.join(", ");
      excelData.push([
        lab.labID,
        `${lab.labName},\n${lab.labAddress}`,
        `${lab.labContact}, ${lab.labContactNo}`,
        formattedEmails,
        new Date(lab.createdAt).toLocaleDateString("en-GB").replace(/\//g, "-"),
        `${resultString}`,
        lab.paymentMethod,
        lab.labGstin,
        lab.labNabl
      ]);
    });

    excelData.push(['']); // Add empty row for spacing
    excelData.push(['']); // Add another empty row for spacing
  });

  const ws = XLSX.utils.aoa_to_sheet(excelData);
  const wb = XLSX.utils.book_new();
  XLSX.utils.book_append_sheet(wb, ws, "Sheet1");

  XLSX.writeFile(wb, 'output.xlsx');
};


