function base64urlDecode(str) {
    // Replace non-url-safe characters
    str = str.replace(/-/g, '+').replace(/_/g, '/');
    
    // Pad with '=' to make the length a multiple of 4
    while (str.length % 4) {
      str += '=';
    }
    
    // Decode base64 string
    const decoded = atob(str);
    
    // Convert to a UTF-8 string
    const utf8Str = decodeURIComponent(
      Array.prototype.map.call(decoded, function(c) {
        return '%' + ('00' + c.charCodeAt(0).toString(16)).slice(-2);
      }).join('')
    );
    
    return utf8Str;
  }
  
  export function decodeJWT(token) {
    const parts = token.split('.');
  
    if (parts.length !== 3) {
      throw new Error('Invalid JWT token');
    }
  
    const payload = parts[1];
    const decodedPayload = base64urlDecode(payload);
  
    return JSON.parse(decodedPayload);
  }
  


  export const getCookie = (name) => {
    const value = `; ${document.cookie}`;
    const parts = value.split(`; ${name}=`);
    if (parts.length === 2) return parts.pop().split(';').shift();
  };
  
  