import React, { useState,useEffect } from "react";
import { useSelector,useDispatch } from "react-redux";
import { Link, Navigate } from "react-router-dom";
import {createdAt,  set_id,setPaymentMethod} from "../../redux//userSclice"
import axios from "../../api/axiosPublic";

function EditRegistration({isPrivate= false}) {
  const dispatch = useDispatch();
  const user = useSelector((state) => state.user);
  const [redirect, setRedirect] = useState(false);
  const [btnDisable, setBtnDisable] = useState(false);
  const [paymentMethod, setpaymentMethods] = useState('');
  const [accept, setAccept] = useState(false);
  useEffect(()=>{
    setTimeout(() => {
     localStorage.setItem("userData",JSON.stringify(user))
    }, 300);
 },[user])

  
  async function submitForm() {
    try {
      if (paymentMethod ==="") {
        alert("Kindly fill the payment detals")
        return
      }
      localStorage.removeItem("userData")
    setTimeout(() => {
      localStorage.removeItem("userData")
      
    }, 1000);
      setBtnDisable(true);
      const userData = {...user}
      userData.paymentMethod = paymentMethod
      userData.isPrivate =isPrivate
      const response = await axios.post("/userRegistration", userData);
      console.log(response);
      dispatch(createdAt(response.data.data.createdAt))
      dispatch(set_id(response.data.data._id))
      dispatch(setPaymentMethod(paymentMethod))
      alert(" user registration form is submitted, download the form upon clicking on OK");
      localStorage.removeItem("userData")
      setRedirect(true);
      
    } catch (error) {
      console.log(error);
      alert("User Registration Failed");
    } finally {
     
        setBtnDisable(false);
    }
  }

  


  return (
    <div className="">
      <div className="grid  grid-cols-2 gap-6">
        <div className="flex flex-col m-3 w-full">
          <div className="flex justify-between">
            <h1 className="text-2xl font-bold">Edit Registration</h1>
            <Link to="/userregistration">
              <button
                type="button"
                className="bg-blue-500 font-bold text-white px-4 py-2 rounded-md"
              >
                Edit Lab Details
              </button>
            </Link>
          </div>
          <table className="mt-6 w-full text-md border-2 border-black">
            <tbody className="border-2 border-black">
              <tr className="border-2 border-black">
                <td className="font-bold w-40 text-black mr-10 border-2 border-black">
                  Lab Name:
                </td>
                <td className="pl-10">{user.labName}</td>
              </tr>
              <tr className="border-2 border-black">
                <td className="font-bold text-black mr-10 border-2 border-black">
                  Lab Contact Person:
                </td>
                <td className="pl-10">{user.labContact}</td>
              </tr>
              <tr className="border-2 border-black">
                <td className="font-bold text-black mr-10 border-2 border-black">
                  Lab Contact No:
                </td>
                <td className="pl-10">{user.labContactNo}</td>
              </tr>
              <tr className="border-2 border-black">
                <td className="font-bold text-black mr-10 border-2 border-black">
                  Lab Address:
                </td>
                <td className="pl-10">{user.labAddress}</td>
              </tr>
              <tr className="border-2 border-black">
                <td className="font-bold text-black mr-10 border-2 border-black">
                  Lab Email:
                </td>
                <td className="pl-10">{user.labEmail}</td>
              </tr>
              <tr className="border-2 border-black">
                <td className="font-bold text-black mr-10 border-2 border-black">
                  Lab Gstin:
                </td>
                <td className="pl-10"> {user.labGstin}</td>
              </tr>
              <tr className="border-2 border-black">
                <td className="font-bold text-black mr-10 border-2 border-black">
                  Lab Nabl:
                </td>
                <td className="pl-10"> {user.labNabl}</td>
              </tr>
            </tbody>
          </table>
        </div>

        <div className="flex flex-col m-3 h-80 overflow-y-scroll">
          <div className="flex justify-between">
            <h1 className="text-2xl font-bold ">Edit Rounds</h1>
            <Link to="/registration">
              <button
                type="button"
                className="bg-blue-500 font-bold text-white px-4 py-2 rounded-md"
              >
                Add Round
              </button>
            </Link>
          </div>

          <table className="border-2 mt-6 w-full border-black ">
            <thead className="bg-gray-200">
              <tr>
                <th className="w-1/2">Round Name</th>
                <th className="w-1/2">Round Price</th>
              </tr>
            </thead>
            {user.round.map((round, index) => (
              <tbody key={index}>
                <tr>
                  <td className="border-2 border-black text-center">
                    {round.roundName}
                  </td>
                  <td className="border-2 border-black text-center">
                    ₹ {round.roundPrice} + 18% GST
                  </td>
                </tr>
              </tbody>
            ))}
            <tfoot>
              <tr>
                <td className="text-center font-bold">Total</td>
                <td className="text-center font-bold">
                  ₹{" "}
                  {user.round.reduce(
                    (acc, round) => acc + parseInt(round.roundPrice),
                    0
                  )}{" "}
                  + 18% GST
                </td>
              </tr>
            </tfoot>
          </table>
        </div>
        {redirect ? isPrivate ? <Navigate to="/APTlabUserPannel/downloadperforma" />:<Navigate to="/download" />:""}
      </div>
      <form className="flex justify-center items-center m-3">
        <div className="flex w-2/4 ">
      <label className="border-2 border-black border-r-transparent p-2 w-2/4  font-bold">Payment details:</label>
      <textarea type="text" name="paymentMethod" className="border-2 border-black p-2 w-3/4  " onChange={e=> setpaymentMethods(e.target.value)} placeholder="Provide NEFT-UTR Or reference/Cheque number etc and date of transaction or email details to info@aashvipt.com" />
        </div>

      </form >
      <div className="w-11/12 flex flex-col justify-center items-center ml-10 space-x-6">
        <label className=" w-11/12 text-lg font-bold ">
        <input
          type="checkbox"
          onChange={(e) => {
            setAccept((prev) => (prev = !prev));
          }}
          className="mr-10"
          name="accept"
          id="accept"
          defaultValue={false}
        />
        I have read and agree to the Terms & Conditions of the Registration Policy.  
        <a target="_blank" className="text-blue-500 text-sm underline ml-1" href="https://aashvipt.com/pt-calendar/#dearflip-df_552/3/">Terms and Conditions.</a>
        </label>   
       <button
        type="button"
        disabled={btnDisable || !accept}
        onClick={submitForm}
        className={` ${btnDisable || !accept?"bg-red-500":"bg-green-500"}  mt-8 w-40 font-bold text-white px-4 py-2 rounded-md`}
      >
        {!accept?"Accept T&C": !btnDisable?"Submit Form":"Loading..."}
      </button>
      </div>
    </div>
  );
}

export default EditRegistration;
