import React,{useEffect} from 'react';
import { Navigate, Outlet, useLocation,} from 'react-router-dom';
import { useAuth } from '../hooks/useAuth';
import { decodeJWT } from "./aashviPT/Login/jwtDecode";
import PrivateNav from "./aashviPT/PrivateNav/PrivateNav"
const PrivateRoute = () => {
  const { user } = useAuth();
  const location = useLocation()

  useEffect(() => {
    const token = localStorage.getItem("token")
    console.log("Token",token)
    if (token) {
      try {
        const decoded = decodeJWT(token);
        console.log("Json",decoded)
      } catch (error) {
        console.error('Invalid token', error);
      }
    }
  });


  if (!user) {
    return <Navigate state={{ from: location }} to='/APTlogin'/>
  }

  return (
    <>
      <PrivateNav />
    </>
  );
};

export default PrivateRoute;
