import {createSlice} from "@reduxjs/toolkit" 

const initialState = {
    year:"",
    january:[],
    february:[],
    march:[],
    april:[],
    may:[],
    june:[],
    july:[],
    august:[],
    september:[],
    october:[],
    november:[],
    december:[],
    isUpdate:false
    
}

export const CalenderSclice = createSlice({
    name:"Calender",
    initialState,
    reducers:{
        setYear(state,action){
            state.year = action.payload
        },
        setJanuary(state,action){
            state.january = action.payload
        },
        setFebruary(state,action){
            state.february = action.payload
        },
        setMarch(state,action){
            state.march = action.payload
        },
        setApril(state,action){
            state.april = action.payload
        },
        setMay(state,action){
            state.may = action.payload
        },
        setJune(state,action){
            state.june = action.payload
        },
        setJuly(state,action){
            state.july = action.payload
        },
        setAugust(state,action){
            state.august = action.payload
        },
        setSeptember(state,action){
            state.september = action.payload
        },
        setOctober(state,action){
            state.october = action.payload
        },
        setNovember(state,action){
            state.november = action.payload
        },
        setDecember(state,action){
            state.december = action.payload
        },
        setIsupdate(state,action){
            state.isUpdate = action.payload
        },
        resetCalender:(state)=>{
    state.year=""
    state.january=[]
    state.february=[]
    state.march=[]
    state.april=[]
    state.may=[]
    state.june=[]
    state.july=[]
    state.august=[]
    state.september=[]
    state.october=[]
    state.november=[]
    state.december=[]
    state.isUpdate=false
        }
    }
    
})

export default CalenderSclice.reducer

export const {setIsupdate,setJanuary,setFebruary,setMarch,setApril,setMay,setJune,setJuly,setAugust,setSeptember,setOctober,setNovember,setDecember,setYear,resetCalender} = CalenderSclice.actions
